//import { useContext } from "react"; 
import * as React from 'react';
import { 
  AppBar, 
  Toolbar, 
  IconButton, 
  Typography, 
  Button,
  Menu,
  MenuItem
} from '@mui/material';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { useNavigate } from "react-router-dom"

function Navbar() {

  const navigate = useNavigate();
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const open = Boolean(anchorEl);

  const version = window._env_.VERSION
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = (e) => {
    navigate(e);
    //setAnchorEl(null);
  };
  
  return (
    <AppBar position="sticky">
      <Toolbar>
    
        <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        >
          <AutoStoriesIcon />
        </IconButton>
    
      <Typography variant="h6"
        component="div" sx={{ flexGrow: 1 }}>
        Mancolista { version }
      </Typography>
      
      {/*<Button href="/logout" color="inherit" >Logout</Button>*/}
    
      <div>
        <Button
          id="basic-button"
          color="inherit"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          Menu
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => handleClose('/dashboard')}>Dashboartd</MenuItem>
          <MenuItem onClick={() => handleClose('/favorites')}>Favorites</MenuItem>
          <MenuItem onClick={() => handleClose('/logout')}>Logout</MenuItem>
          
          
        </Menu>
      </div>
      </Toolbar>
    </AppBar>
    );
    
  }
  
  export default Navbar;
import axios from 'axios'

const API_URL = window._env_.API_URL;

const ApiServer = axios.create({
  baseURL: API_URL
});

const DecodeJWT= (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

ApiServer.interceptors.request.use(
  (config) => {
    //console.log(window._env_.API_URL);
    const token = localStorage.getItem('token');
    const decoded = DecodeJWT(token);
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['username'] = decoded.username;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

ApiServer.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      window.location.href = '/';
    }
  });


export default ApiServer;
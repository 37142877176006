import { Buffer } from "buffer";
import React, { useState, useEffect  } from "react";
import { 
  Box, 
  Stack,
  Chip 
} from '@mui/material';
import ApiServer from "../ApiServerConfig";
import {
  DataGrid,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';

const generateImageFromBuffer = buffer => {
  let _buffer = Buffer.from(buffer, 'base64');
  return _buffer.toString('base64');
};

const columns = [
  //{ field: 'rowid', headerName: 'id', width: 70 },
  { field: 'month', headerName: 'Mese', width: 100 },
  /*
  { field: 'image', 
    headerName: 'Image', 
    width: 100,
    renderCell: (params) => {
      let defaultImgSrc = "https://placehold.co/600x400/png";
      if ( params.value ){
        const image = generateImageFromBuffer(params.value);
        defaultImgSrc = "data:image/png;base64," + image;
      }
      return (
        <>
          <img src={defaultImgSrc} alt="none" width={100} height="auto" />
        </>
      );
    }
  },
  */
  { field: 'title', headerName: 'Titolo', width: 400 },
  { field: 'number', headerName: '#', type: 'number', width: 30 },
  { field: 'catalog', headerName: 'Collana', width: 400 },
  { field: 'series', headerName: 'Serie', width: 200 },
  { field: 'series_number', headerName: 'Serie#', width: 50 },
  { field: 'editor', headerName: 'Editore', width: 200 },
  { field: 'price', headerName: 'Prezzo €', type: 'number', width: 90 },
];


export default function DataTable() {

  const [spinner, showSpinner] = useState(false);
  const [rows, setRows] = useState([]);
  const [toApplyFilter, settoApplyFilter] = useState(true);
  const [appliedFilterStr, setAppliedFilterStr] = useState("FILTERED");
  const [toApplyLast, settoApplyLast] = useState(true);
  const [appliedLastStr, setAppliedLastStr] = useState("LAST");
  const [errorMessage, setErrorMessage] = useState(null);
    

  const getRows = async (toApplyFilter, toApplyLast) => {
    //console.log("getRows InVOCATO ["+toApplyFilter+"]["+toApplyLast+"]");
    const filtered = toApplyFilter ? "filtered" : "";
    const last = toApplyLast ? "/last" : "";
    showSpinner(true);
    try {
      const response = await ApiServer.get("/fo"+last+"/"+filtered);
      setRows(response.data);
      showSpinner(false);
    } catch (error) {
      console.error("Authentication failed:", error);
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data); // Set the error message if present in the error response
      } else {
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  useEffect(() => {
    getRows(toApplyFilter,toApplyLast)
  },[toApplyFilter,toApplyLast]);


  function EditToolbar(props) {

    const handleFilterClick = () => {
      settoApplyFilter((current) => !current);
      setAppliedFilterStr(toApplyFilter?"NOT FILTERED":"FILTERED")
    };

    const handleLastClick = () => {
      settoApplyLast((current) => !current);
      setAppliedLastStr(toApplyLast?"ALL":"LAST");
    };
  
    return (
      <GridToolbarContainer>
      {/*
        <Button color="primary" startIcon={<AddIcon />} onClick={handleFilterClick}>
          {toApplyFilterStr} Favorites
        </Button>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleLastClick}>
          {toApplyLastStr}
        </Button>
      */}
        <Stack
          direction="row" 
          spacing={2} 
          justifyContent="flex-end"
          alignItems="rigth">
          
          <Chip label={appliedLastStr} onClick={handleLastClick} color="primary" />
          <Chip label={appliedFilterStr} onClick={handleFilterClick} color="success" />
        </Stack>
      </GridToolbarContainer>
    );
  }

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
      
    > 
      <div style={{ height: '400', width: '100%' }}>
      {spinner && <LinearProgress />}
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}{" "}
        <DataGrid
          initialState={{
            columns: {
              columnVisibilityModel: {
                series: false,
                series_number: false,
              },
            },
            sorting: {
              sortModel: [{ field: 'mese', sort: 'desc' }],
              pagination: {
                paginationModel: { page: 0, pageSize: 30 },
              },
            },
          }}
          density="compact"
          rows={rows}
          slots={{ toolbar: EditToolbar }}
          columns={columns}
          pageSizeOptions={[30, 50, 100]}
          //checkboxSelection
        />
      </div>
    </Box>
  );
}
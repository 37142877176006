//import { useContext } from "react"; 
import React, { useContext } from "react";
import { AuthContext } from "./AuthContext";
import { Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { appTheme } from "./layout/Theme";
import Navbar from "./layout/Navbar";
import DataTableFavorites from "./layout/TableFavorites";

// https://mui.com/x/react-data-grid/editing/
function Favorites() {
  const { token, loading } = useContext(AuthContext);
  
  if (loading) {
    return null;
  }

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return (
    <ThemeProvider theme={appTheme}>
      <Navbar />
      {/*<Head />*/}
      <DataTableFavorites />
    </ThemeProvider>  
  );

}

export default Favorites;
